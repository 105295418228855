import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  datoApi,
  defaultSettings,
  getApiSettingsFromHost,
} from '@hultafors/hultaforsgroup/api';
import { spacing } from '@hultafors/hultaforsgroup/helpers';
import {
  GlobalFields,
  PageNotFoundContentFragment,
  PageProps,
} from '@hultafors/hultaforsgroup/types';

const ContentContainer = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then(
    (module) => module.ContentContainer,
  ),
);
const Link = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.SharedLink),
);
const HTag = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.HTag),
);
const Page = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((module) => module.Page),
);
const Paragraph = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Paragraph),
);

type PageNotFoundProps = PageProps<PageNotFoundContentFragment>;

export const getStaticProps: GetStaticProps<PageNotFoundProps> = async ({
  locale,
}) => {
  try {
    // Fetch default locale data
    const settings = getApiSettingsFromHost(defaultSettings.hostname);
    const dato = datoApi(locale);

    // Fetch page content
    const {
      data: { pageNotFound: content, ...globalFields },
    } = await dato.getPageNotFound();

    const props: PageNotFoundProps = {
      content,
      ...(globalFields as unknown as GlobalFields),
      settings,
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      props: {} as PageNotFoundProps,
    };
  }
};

interface PageNotFoundResponse extends GlobalFields {
  content: PageNotFoundContentFragment;
}

const PageNotFound: NextPage<PageNotFoundProps> = ({
  // content: defaultContent,
  // settings: defaultSettings,
  content,
}) => {
  // const [, setSettings] = useState<ApiSettings>(defaultSettings);
  // const [content, setContent]
  //   = useState<PageNotFoundContentFragment>(defaultContent);
  // const { setGlobal, setMenu, setFooter } = useGlobal();
  // const { data: pageData, error: pageError } = useSWR<PageNotFoundResponse>(
  //   '/api/pageNotFound',
  //   {
  //     revalidateOnMount: true,
  //   },
  // );
  // useEffect(() => {
  //   setSettings(getApiSettingsFromHost(window.location.hostname));
  // }, []);

  // useEffect(() => {
  //   if (pageData?.content) {
  //     setContent(pageData.content as PageNotFoundContentFragment);
  //   }
  //   if (pageData?.global) {
  //     setGlobal(pageData.global as GlobalFragment);
  //   }
  //   if (pageData?.footer) {
  //     setFooter(pageData.footer as FooterFragment);
  //   }
  //   if (pageData?.menu) {
  //     setMenu(pageData.menu as MenuFragment);
  //   }
  // }, [pageData]);

  // useEffect(() => {
  //   if (pageError) {
  //     setContent(defaultContent);
  //   }
  // }, [pageError]);

  return (
    <Page
      metadata={{
        description: content.description,
        title: content.header,
      }}
    >
      <ContentContainer style={{ marginBlock: spacing.large }}>
        <HTag type="h1">{content.header}</HTag>
        {content.description && (
          <Paragraph adjustStyles={{ marginBlock: spacing.regular }}>
            {content.description}
          </Paragraph>
        )}
        <Link href="/" adjustStyles={{ marginBlock: `${spacing.regular} 0` }}>
          {content.backToHomeScreenButton}
        </Link>
      </ContentContainer>
    </Page>
  );
};

export default PageNotFound;
